*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@font-face {
    font-family: "Martellas";
    src: url("./fonts/MartellasCapsRounded.otf");
}
@font-face {
    font-family:"Poppins";
    src: url("./fonts/Poppins-Regular.ttf");
    font-weight: 400;
}
@font-face {
    font-family: "Poppins";
    src: url("./fonts/Poppins-Bold.ttf");
    font-weight: 700;
}
@font-face {
    font-family: "Martellas";
    src: url("./fonts/MartellasCapsRounded.otf");
}
@font-face {
    font-family:"Poppins";
    src: url("./fonts/Poppins-Regular.ttf");
    font-weight: 400;
}
@font-face {
    font-family: "Poppins";
    src: url("./fonts/Poppins-Bold.ttf");
    font-weight: 700;
}


body {
    background-color: transparent;
    width: 100%;
    height: 100%;
}

.tl-nft-con
{
    display: flex;
    justify-content: center;
    align-items: flex-start;    
}

.tl-nft-con .tl-nft-img-box
{
    width: 100%!important;
    height: 100%!important;
    position: relative;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0);
    
}

.overlay-menu-active-1 .nft-details-content-1,
.overlay-menu-active-2 .nft-details-content-2,
.overlay-menu-active-3 .nft-details-content-3,
.overlay-menu-active-4 .nft-details-content-4 {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}


.tl-nft-con .tl-nft-img-box .tl-nft-bg-img {
display: block;
width: 100%;
height: 100%;
}

.tl-nft-con .tl-nft-img-box .tl-nft-menu 
{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-top: 30px;
    padding-right: 20px;
}

.tl-nft-con .tl-nft-img-box .tl-nft-menu img
{
    width: 35px;
    height: 35px;
    padding: 5px;
    background-color: #000;
    border-radius: 5px;
    transition: all 0.4s ease-in-out;
}

.tl-nft-con .tl-nft-img-box .tl-nft-menu img:hover
{
    background-color: #777;
    cursor:pointer;

}

.tl-nft-con .tl-nft-info-content,
.tl-nft-con .tl-nft-story-content,
.tl-nft-con .tl-nft-carving-content{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    font-family: "Poppins", sans-serif;
    transform: scale(0);
    transform-origin: center;
    -webkit-transform: scale(0);
    -webkit-transform-origin: center;
    transition: transform 0.4s ease;
    padding: 20px;
    overflow: hidden;
    z-index: 10;

    
}
.tl-nft-overly-info-active .tl-nft-info-content,
.tl-nft-overly-story-active .tl-nft-story-content,
.tl-nft-overly-carving-active .tl-nft-carving-content
{
    transform: scale(1);
    z-index: 10;

}
.tl-nft-con .tl-nft-close-icon
{
    position: absolute;
    top: 38px;
    right: 26px;
}
.tl-nft-con .tl-nft-close-icon svg{
    width: 20px;
    display: block;
    color: #fff;
    fill: #fff;
    cursor:pointer;
}

.tl-nft-con .tl-nft-info-content h2,
.tl-nft-con .tl-nft-story-content h2,
.tl-nft-con .tl-nft-carving-content h2

{
    font-family: "Martellas";
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    line-height: 1.1;
}

.tl-nft-con .tl-nft-info-content .tl-nft-content-text{
    display: flex;
    align-items: start;
}
.tl-nft-con .tl-nft-info-content .tl-nft-content-text > *{

    width: 50%;
}
.tl-nft-con .tl-nft-info-content .tl-nft-content-text .tl-nft-text-header{
    font-family: Poppins,sans-serif;
    font-size: 17px;
    font-weight: 700;
    line-height: 1.5;
    padding: 10px 10px 10px 0px;
    
}
.tl-nft-con .tl-nft-info-content .tl-nft-content-text .tl-nft-text-info
{
    font-family: Poppins,sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    padding: 10px 10px 10px 0px;
}

.tl-nft-con .tl-nft-info-content .tl-nft-content-text .tl-nft-social-media
{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 10px 10px 0px;
  

}
.tl-nft-con .tl-nft-info-content .tl-nft-content-text .tl-nft-social-media svg
{
  width: 50px;
  display: block;
  color: #fff;
  fill: #fff;

}
.tl-nft-con .tl-nft-info-content .tl-nft-terms-conditions {
position: absolute;
left: 50%;
bottom: 10px;
transform: translate(-50%,0%);

}
.tl-nft-con .tl-nft-info-content .tl-nft-terms-conditions a{
    font-family: Poppins,sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
}
.tl-nft-con .tl-nft-info-content .tl-nft-content-text .tl-nft-text-info a,
.tl-nft-con .tl-nft-info-content .tl-nft-terms-conditions a,
.tl-nft-con .tl-nft-info-content .tl-nft-content-text .tl-nft-social-media a{
    color: #fff;
    text-decoration: none;
    background-image: linear-gradient(#fff, #fff);
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 0% 3px;
    transition: background-size 0.4s ease;
    padding-bottom: 3px;
}
.tl-nft-con .tl-nft-info-content .tl-nft-content-text .tl-nft-text-info a:hover,
.tl-nft-con .tl-nft-info-content .tl-nft-terms-conditions a:hover,
.tl-nft-con .tl-nft-info-content .tl-nft-content-text .tl-nft-social-media a:hover
{
    background-size: 100% 3px;

}

.tl-nft-tooltip
{
    position: relative;

}
.tl-nft-tooltip::before
{
    content: "Push Control key + Mouse click to navigate.";
    position: absolute;
    top: -45px;
    left: -12px;
    width: 183px;
    height: 33px;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 1.2;
    text-align: center;
    background-color: #000;
    color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease;
    cursor: default;
    z-index: 1;
    
}

.tl-nft-tooltip:hover::before
{
 opacity: 1;
 visibility: visible;
}

.tl-nft-con .tl-nft-story-content .tl-nft-story-text-info,
.tl-nft-con .tl-nft-carving-content .tl-nft-carving-content-list {
    position: relative;
    left: 0;
    right: 10px;
    bottom: 5px;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: calc(100% - 45px);
    overflow-y: auto;
    text-align: justify;
    color: #ccc;
    font-family: Poppins,sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.6;
    padding-right: 15px;
    padding-bottom: 15px;
    margin-top: 15px;
}

.tl-nft-con .tl-nft-story-content .tl-nft-story-text-info span
{
    display: block;
    text-indent: 50px;
    padding-bottom: 10px;
}

.tl-nft-carving-content-list::-webkit-scrollbar,
.tl-nft-story-text-info::-webkit-scrollbar {
    width: 12px;
    border: 2px solid #ccc;
}

.tl-nft-carving-content-list::-webkit-scrollbar-thumb,
.tl-nft-story-text-info::-webkit-scrollbar-thumb {
    background-color: #ccc;
    max-height: 60px;
}

@media (max-width:600px) {
    .tl-nft-con .tl-nft-img-box {
        width: 100%!important;
        height: 100%!important;
    }

    .tl-nft-con .tl-nft-info-content .tl-nft-content-text .tl-nft-social-media svg {
        width:30px
    }

    .tl-nft-con .tl-nft-info-content .tl-nft-content-text .tl-nft-text-header,
    .tl-nft-con .tl-nft-info-content .tl-nft-content-text .tl-nft-text-info,
    .tl-nft-con .tl-nft-info-content .tl-nft-content-text .tl-nft-social-media
    {
        padding: 5px 10px 5px 0px;  
    }
  
}

.accordion {
    width: 100%;
    position: relative;
}

.accordion input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.accordion .row {
    display: flex;
}

.accordion .row .col {
    flex: 1;
}

.accordion .tab {
    width: 100%;
    overflow: hidden;
}

.accordion .tab-label {
    display: flex;
    justify-content: center;
    cursor: pointer;
    font-size: 0.9rem;
    background-color: #7f7f7f;
    padding: 1rem;
    border-radius: 2px;
    position: relative;
}

.accordion .tab-content {
    max-height: 0;
    transition: all 0.4s ease-in-out;
}

.accordion input:checked~.tab-content {
    max-height: 100vh;
}

.accordion input:checked+.tab-label::after {
    transform: rotate(90deg);
}

.error-tab {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding: 2rem;
}

.error-tab .logo {
    max-width: 70%;
}

.error-tab .error-code {
    position: absolute;
    bottom: 0.5rem;
    left: 1rem;
    font-size: 1.2rem;
    color: #fff;
    text-shadow: 2px 2px #000;
}

.error-tab p {
    margin-top: 2rem;
    text-align: center;
    font-size: 1.3rem;
}
